
/*--------------------------------------------*/
/*Start Responsive Tab and Mobile Views*/
/*--------------------------------------------*/

/*--------------------------------------------*/
/* Laptop with Desktop (1600px) View*/
/*--------------------------------------------*/
@media all and (max-width:1600px){

}

/*--------------------------------------------*/
/* Laptop with Desktop (1400px) View*/
/*--------------------------------------------*/
@media all and (max-width:1400px){
}



/*--------------------------------------------*/
/* Laptop with Tab (1199px) View*/
/*--------------------------------------------*/
@media all and (max-width:1199px){
}

/*--------------------------------------------*/
/* Laptop with Tab (1024px) View*/
/*--------------------------------------------*/
@media all and (max-width:1024px){


}


/*--------------------------------------------*/
/* Mini Laptop with Tab (991px) View*/
/*--------------------------------------------*/
@media all and (max-width: 991px){

}

/*--------------------------------------------*/
/* Mini Laptop with Tab (991px) View*/
/*--------------------------------------------*/
@media all and (max-width: 992px){
    .navbar-expand-lg.navbar-vertical~.navbar, .navbar-expand-lg.navbar-vertical~.page-wrapper {
        margin-left: 0px;
     }
     .navbar-vertical.navbar-expand-lg {
         height: 100%;
         position: fixed;
         z-index: 9;
     }
     .page-wrapper .page-header .col{
           display: flex;
           align-items: center;
     }
     .page-wrapper .page-header .page-title{
         margin-top: -4px;
         margin-left: 10px;
     }
     .page-wrapper .page-header{
         padding: 10px 10px;
     }
     .navbar-brand{
         display: flex;
          justify-content: center;
     }
     .navbar-vertical.navbar-expand-lg .navbar-brand .navbar-brand-image{
          height: 90px;
         width: 85px;
     }
     .navbar-expand-lg .navbar-collapse{
         display: block;
     }
     .sidebar-container{
          display: block !important;
          position: relative;
     }
     .header-toggle {
       display: block;
       background-image: url(../img/sidebar_toggle.svg);
       height: 1.5rem;
       width: 1.5rem;
       background-repeat: no-repeat;
       background-position: center;
       cursor: pointer;
     }
     .sidebar-container .closebtn {
         display: block;
         position: absolute;
         top: 2%;
         height: 27px;
         width: 27px;
         right: 5%;
         color: white;
         cursor: pointer;
     }
     .before-login-wrapper .page-center { 
         justify-content: space-around;
     }
     .sidebar-left {
         display: none;
     }
     .sidebar-left.show-sidebar {
       display: block; 
    }

}

/*--------------------------------------------*/
/* Mini Tab with All Mobile Devices (767px) View*/
/*--------------------------------------------*/
@media all and (max-width:768px){

}
/*--------------------------------------------*/
/* Mini Tab with All Mobile Devices (667px) View*/
/*--------------------------------------------*/
@media all and (max-width: 667px){


}

/*--------------------------------------------*/
/* Mini Tab with All Mobile Devices (575px) View*/
/*--------------------------------------------*/
@media all and (max-width: 575px){

    .sashboardstatuscountswrapper{
        flex-direction: column;
    }
}
/*--------------------------------------------*/
/* Mini Tab with All Mobile Devices (320px) View*/
/*--------------------------------------------*/
@media all and (max-width: 320px){

}



